import React, { createContext, useContext, useEffect, useState } from "react";
import {
  auth,
  createOrUpdateUser,
  initializePresence,
  db,
} from "../backend-services/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  getTokenFromStorage,
  removeTokenFromStorage,
  setTokenInStorage,
} from "../utils/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(getTokenFromStorage());
  const [isInitialAuthCheck, setIsInitialAuthCheck] = useState(true);

  const refreshUserData = async () => {
    if (!user?.uid) return null;

    try {
      console.log("Refreshing user data...");
      const firestoreData = await createOrUpdateUser({
        ...user,
        lastActive: new Date(),
      });
      console.log("Updated user data:", firestoreData);
      setUserData(firestoreData);
      return firestoreData;
    } catch (error) {
      console.error("Error refreshing user data:", error);
      throw error;
    }
  };

  // Subscribe to user document changes
  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          console.log("User data updated:", data);
          setUserData(data);
          
          if (isInitialAuthCheck && !data.isOnboarded) {
            setUserData(prev => ({
              ...prev,
              isNewUser: true
            }));
          }
        }
        setIsInitialAuthCheck(false);
      },
      (error) => {
        console.error("Error listening to user data:", error);
        setIsInitialAuthCheck(false);
      }
    );

    return () => unsubscribe();
  }, [user?.uid, isInitialAuthCheck]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const idToken = await user.getIdToken();
          setToken(idToken);
          setTokenInStorage(idToken);
          
          const firestoreData = await createOrUpdateUser({
            ...user,
            lastLoginAt: new Date(),
            lastActive: new Date()
          });
          
          setUserData(firestoreData);
          await initializePresence(user.uid);
        } catch (error) {
          console.error("Error syncing user data with Firestore:", error);
        }
      } else {
        setUserData(null);
        setToken(null);
        removeTokenFromStorage();
      }
      setLoading(false);
    });

    const tokenRefreshInterval = setInterval(async () => {
      if (auth.currentUser) {
        try {
          const newToken = await auth.currentUser.getIdToken(true);
          setToken(newToken);
          setTokenInStorage(newToken);
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }, 45 * 60 * 1000); // Refresh token every 45 minutes

    return () => {
      unsubscribe();
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  const value = {
    user,
    loading,
    userData,
    setToken,
    refreshUserData,
    token,
    isInitialAuthCheck
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}