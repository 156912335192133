import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Avatar,
  alpha,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../backend-services/firebase";

const PageContainer = styled(Container)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(
    theme.palette.secondary.main,
    0.1
  )} 100%)`,
  padding: theme.spacing(3),
}));

const OnboardingCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: 500,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  marginBottom: theme.spacing(2),
  border: `4px solid ${alpha(theme.palette.primary.main, 0.3)}`,
}));

const FormField = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
}));

const OnboardingPage = () => {
  const { user, userData, refreshUserData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    displayName: "",
    currency: "USD",
  });

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        displayName: user.displayName || "",
      }));
    }
  }, [user]);

  // Redirect if user is already onboarded
  useEffect(() => {
    if (userData?.isOnboarded) {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (!formData.displayName?.trim()) {
        throw new Error("Display name is required");
      }

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        displayName: formData.displayName.trim(),
        "settings.defaultCurrency": formData.currency,
        isOnboarded: true,
        lastUpdated: new Date(),
      });

      await refreshUserData();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error in onboarding:", error);
      setError(error.message || "Failed to complete onboarding");
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <PageContainer maxWidth={false}>
      <OnboardingCard elevation={3}>
        <StyledAvatar src={user?.photoURL || "/api/placeholder/100/100"} />
        <Typography variant="h4" color="primary" gutterBottom>
          Welcome to WiseSplit!
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 3 }}>
          Let's get your account set up. Please verify or update your information below.
        </Typography>

        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <FormField>
            <TextField
              fullWidth
              label="Display Name"
              name="displayName"
              value={formData.displayName}
              onChange={handleInputChange}
              required
              error={!formData.displayName?.trim()}
              helperText={!formData.displayName?.trim() ? "Display name is required" : ""}
            />
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Default Currency</InputLabel>
              <Select
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                label="Default Currency"
              >
                {Object.entries(SUPPORTED_CURRENCIES).map(([code, details]) => (
                  <MenuItem key={code} value={code}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography>{details.symbol}</Typography>
                      <Typography>
                        {details.name} ({code})
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>

          <SaveButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading || !formData.displayName?.trim()}
          >
            {loading ? <CircularProgress size={24} /> : "Get Started"}
          </SaveButton>
        </form>

        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={() => setError("")} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </OnboardingCard>
    </PageContainer>
  );
};

export default OnboardingPage;